/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import xw from 'twin.macro';

const styles = {
  hide: xw`translate-y-8 opacity-0`,
  show: xw`opacity-100`,
};

export const ProfileImageCard1 = () => {
  const [isShow, setIsShow] = useState(false);

  const caption = 'Dawodiye Camp For Syrian Refugees Dohuk Province, KRI, Iraq';

  return (
    <VisibilitySensor
      offset={{ bottom: 95 }}
      partialVisibility={true}
      onChange={(isVisible) => {
        if (isVisible) {
          setIsShow(true);
        }
      }}
    >
      <figure
        className="mb-8 transition duration-500 ease-in-out"
        css={isShow ? [styles.show] : [styles.hide]}
      >
        <StaticImage
          alt={caption}
          className="w-full h-full filter drop-shadow-lg"
          src="../../images/profile-1.jpg"
        />
        {/* <figcaption className="w-full pt-4 text-sm text-center text-gray-700 dark:text-white">{caption}</figcaption> */}
      </figure>
    </VisibilitySensor>
  );
};
