import { Clients, Hero, Profile, Services } from '@sections/home';
import { getSEO, PageSEO } from '@seo';
import React from 'react';

interface Props {
  location: {
    pathname: string;
  };
}

const Home = ({ location }: Props) => {
  //
  const { pathname } = location;
  const seo = getSEO(pathname);

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-default.jpg"
        pathname={pathname}
        title={seo.title}
      />
      <Hero />
      <Clients />
      <Services />
      <Profile />
    </>
  );
};

export default Home;
