import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import xw from 'twin.macro';

const styles = {
  heading: xw`mt-2 text-2xl font-extrabold leading-10 tracking-tight sm:leading-10 text-center`,
  subHeading: xw`text-lg md:text-2xl leading-6 font-bold`,
};

function Component() {
  return (
    <div className="bg-white dark:bg-gray-100">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-brand-blue" css={[styles.heading]}>
          Worked for UN Agency
        </h2>
        <div className="flex items-center justify-center col-span-1 p-2">
          <StaticImage
            alt="iom logo"
            className="max-h-20"
            objectFit="contain"
            src="../../images/iom.png"
          />
        </div>
        <div className="grid grid-cols-2 gap-2 mt-6 mb-24 md:grid-cols-4 lg:mt-8">
          <div
            className="flex items-center justify-center col-span-1 p-2"
            style={{ backgroundColor: '#5ba7dc' }}
          >
            <StaticImage
              alt="cccm logo"
              className="max-h-20"
              objectFit="contain"
              src="../../images/cccm.png"
            />
          </div>
          <div className="flex items-center justify-center col-span-1 p-2 bg-gray-100">
            <StaticImage
              alt="global-shelter logo"
              className="max-h-20"
              objectFit="contain"
              src="../../images/global-shelter.png"
            />
          </div>
          <div className="flex items-center justify-center col-span-1 p-2">
            <StaticImage
              alt="wash logo"
              className="max-h-20"
              objectFit="contain"
              src="../../images/wash.png"
            />
          </div>
          <div className="flex items-center justify-center col-span-1 p-2">
            <StaticImage
              alt="undp logo"
              className="max-h-20"
              objectFit="contain"
              src="../../images/undp.png"
            />
          </div>
        </div>

        <h2 className="text-brand-blue" css={[styles.heading]}>
          Cooperations
        </h2>
        <div className="grid content-center max-w-2xl grid-cols-2 gap-2 mx-auto mb-24 md:mt-8 md:grid-cols-4">
          <div className="flex items-center justify-center col-span-1 p-2 bg-white">
            <StaticImage
              alt="urs logo"
              className="max-h-20"
              objectFit="contain"
              src="../../images/urs.jpg"
            />
          </div>
          <div className="flex items-center justify-center col-span-1 p-2">
            <StaticImage
              alt="klia logo"
              className="max-h-24"
              objectFit="contain"
              src="../../images/klia.jpg"
            />
          </div>
          <div className="flex items-center justify-center col-span-1 p-2">
            <StaticImage
              alt="klia college logo"
              className="max-h-24"
              objectFit="contain"
              src="../../images/klia-college.png"
            />
          </div>
          <div className="flex items-center justify-center col-span-1 p-2">
            <StaticImage
              alt="sunlid logo"
              className="max-h-24"
              objectFit="contain"
              src="../../images/sunlid.jpg"
            />
          </div>
        </div>
        <h2 className="text-brand-blue" css={[styles.heading]}>
          Registered Trainer
        </h2>
        <div className="flex items-center justify-center col-span-1 p-2">
          <StaticImage
            alt="hrdf logo"
            className="max-h-24"
            objectFit="contain"
            src="../../images/hrdf.png"
          />
        </div>
      </div>
    </div>
  );
}

export const Clients = Component;
