/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import xw from 'twin.macro';

const styles = {
  hide: xw`translate-y-8 opacity-0`,
  show: xw`opacity-100`,
};

export const ProfileImageCard2 = () => {
  const [isShow, setIsShow] = useState(false);

  const caption = '30 years of experience';

  return (
    <VisibilitySensor
      offset={{ bottom: 95 }}
      partialVisibility={true}
      onChange={(isVisible) => {
        if (isVisible) {
          setIsShow(true);
        }
      }}
    >
      <figure
        className="mb-8 transition duration-500 ease-in-out"
        css={isShow ? [styles.show] : [styles.hide]}
      >
        <StaticImage
          alt={caption}
          className="w-full h-full filter drop-shadow-lg"
          src="../../images/profile-2.jpg"
        />
        {/* <figcaption className="w-full py-4 mt-2 text-sm text-center text-gray-800 bg-gray-200 shadow-2xl dark:bg-gray-700 dark:text-white">
          <p className="mb-2 text-base">{caption}</p>
          <ul className="flex justify-center space-x-4">
            <li>Corporate Sectors</li>
            <li className="border-r-2 border-gray-500 dark:border-gray-400" />
            <li>Humanitarian Work</li>
            <li className="border-r-2 border-gray-500 dark:border-gray-400" />
            <li>12 years with IOM</li>
          </ul>
        </figcaption> */}
      </figure>
    </VisibilitySensor>
  );
};
