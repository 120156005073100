/* eslint-disable max-len */
import { Button } from '@chakra-ui/react';
import { sitedata } from '@data';
import { FacebookIcon, LinkedInIcon, TwitterIcon } from '@svg';
import React from 'react';
import xw from 'twin.macro';
import { ProfileImageCard1 } from './profileImageCard1';
import { ProfileImageCard2 } from './profileImageCard2';

const styles = {
  heading: xw`mt-2 text-3xl font-extrabold leading-10 tracking-tight sm:text-4xl sm:leading-10`,
};

function Component() {
  //
  const { socialMedia } = sitedata;
  const { facebookProfileId, linkedInHandle, twitterHandle } = socialMedia;

  return (
    <div className="max-w-lg py-16 mx-auto overflow-hidden text-black dark:text-white lg:max-w-5xl lg:py-24">
      <div className="px-4 py-4 mb-4 text-center sm:px-6 lg:px-8">
        <p className="text-base font-semibold leading-6 tracking-wide uppercase text--color-brand-1">
          Raja Kuppusamy
        </p>
        <h2 className="text--color-heading-1" css={[styles.heading]}>
          Brief Profile
        </h2>
        <div className="py-4 mt-6 text-sm text-center text-gray-800 bg-gray-200 rounded-lg dark:bg-gray-700 dark:text-white">
          <p className="mb-2 text-base md:text-xl">36 years of experience</p>
          <div className="justify-center lg:flex">
            <div className="px-2">Corporate Sectors</div>
            <div className="border-r-2 border-gray-500 dark:border-gray-400" />
            <div className="px-2">Academic</div>
            <div className="border-r-2 border-gray-500 dark:border-gray-400" />
            <div className="px-2">Humanitarian Relief</div>
            <div className="border-r-2 border-gray-500 dark:border-gray-400" />
            <div className="px-2">15 years with UN Migration Agency (IOM)</div>
          </div>
        </div>
      </div>
      <div className="grid gap-8 px-4 mb-12 lg:grid-cols-2 lg:gap-x-16 lg:mb-24">
        <div>
          <ProfileImageCard1 />
          <div className="py-8 mb-12 text-center bg-gray-200 rounded-lg dark:bg-gray-700">
            <div className="mb-4 font-semibold text--color-heading-1">
              Follow / See Profile
            </div>
            <div className="flex justify-center mb-6 space-x-6">
              <a
                aria-label="linkedIn"
                href={`https://www.linkedin.com/in/${linkedInHandle}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
              <a
                aria-label="facebook"
                href={`https://www.facebook.com/profile.php?id=${facebookProfileId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                aria-label="twitter"
                href={`https://twitter.com/${twitterHandle}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterIcon />
              </a>
            </div>
            <div>
              <Button as="a" href="/cv.pdf" size="md" target="_blank">
                Download CV
              </Button>
            </div>
          </div>
        </div>
        <div>
          <p className="px-2 mb-4 lg:px-0">
            I am a professional humanitarian emergency response infrastructure
            engineer having skills, knowledge and experience in DRR project
            management. With more than 36 years service in construction
            management of airports and railways, teaching in academic
            institutions, of which 15 years as technical advisor, head of
            engineering department, project manager, and capacity building
            trainer with UN IOM (UN Migration Agency). I have executed, and
            managed major emergency disaster relief response programs and
            projects with the UN IOM field mission in conflict and post crisis
            regions in South Asia and the Middle East region. Primarily served
            in Indonesia, Afghanistan, Nepal, Bangladesh (Cox Bazar), and Iraq
            country missions.
          </p>
          <p className="px-2 mb-4 lg:px-0">
            UN IOM and partner agencies have given endorsement in my work
            recognition having niche emergency disaster response project
            management skills and experience in shelter, internal displaced
            people and refugee camp construction, and in WASH infrastructure
            projects. Reference available in personal website under gallery. I
            am capable of handling multi-disciplinary tasks in 4 skill areas;
          </p>
          {/* <p className="px-2 mb-2 lg:px-0">
            Raja is capable of handling multi-disciplinary tasks overseeing
            humanitarian disaster response infrastructure projects. 4 of his
            skill areas are;
          </p> */}
          <ul className="ml-6 text-black list-disc list-outside dark:text-indigo-200">
            <li>Technical project advisor</li>
            <li>Technical training and capacity building</li>
            <li>
              Technical audit and assessment of community development projects
            </li>
            <li>
              Preparation, development, execution and monitoring and control
              humanitarian core standards construction documents for tendering
              and construction management emergency and post emergency projects
              (reconstruction and community development projects)
            </li>
          </ul>
        </div>
      </div>
      <div className="grid px-4 lg:grid-cols-2 lg:gap-x-16">
        <div>
          <p className="px-2 mb-4 lg:px-0">
            I have received accolades and recognitions in the publications of
            technical guidelines reference handbooks in emergency shelter
            rehabilitation, WASH infrastructure booklet for campsite and
            out-of-site community projects, Bill of Quantity ( BOQ) standard
            reference handbook for procurement and field relief workers and a
            full construction documents for humanitarian project requirements in
            tender and execution.
          </p>
          <p className="px-2 mb-4 lg:px-0">
            My advanced academic qualifications are undergraduate in civil
            building engineering and postgraduate in construction project
            management, and emergency disaster management endorsed by the UN
            agency (IFRC- TISS).
          </p>
          <p className="px-2 mb-4 lg:px-0">
            My passion, communication and strong leadership skills to succeed in
            my work related activities are highly respected by my peers in the
            United Nation, academics and government institutions.
          </p>
        </div>
        <div>
          <ProfileImageCard2 />
        </div>
      </div>
    </div>
  );
}

export const Profile = Component;
