/* eslint-disable max-len */
import { Button } from '@chakra-ui/react';
import { Modal, ModalContent } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import xw from 'twin.macro';

const styles = {
  container: xw`box-border fixed inset-0 overflow-auto z-15001 flex dark:bg-gray-900 bg-gray-700`,
  content: xw`box-border max-w-full mx-auto my-0 dark:bg-black bg-white lg:table-row`,
  imageContainer: xw`box-border text-center relative inline-block`,
  imageContent: xw`box-border relative align-top text-center lg:table-cell`,
  innerLayout: xw`box-border max-h-full relative dark:bg-gray-700 bg-white lg:table`,
  outerLayout: xw`box-border m-auto`,
  videoInnerContainer: xw`absolute top-0 left-0 w-full h-full`,
  videoOuterContainer: xw`absolute w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`,
};

function Component() {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  const handleOpenVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  return (
    <div className="relative grid gap-4 mb-4 overflow-hidden z-1 lg:grid-cols-2 lg:mb-20">
      <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="text-center lg:text-left">
          <h1 className="mb-12 font-extrabold">
            <div className="pt-6 mb-2 text-4xl tracking-tight sm:text-5xl md:text-6xl sm:pt-8 md:pt-20 text--color-heading-1">
              Raja Kuppuswamy
            </div>
            <div className="text-3xl leading-2 text--color-brand-1">
              Emergency Response Consultant
            </div>
          </h1>
          <p className="mx-auto mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-lg md:mt-5 md:text-xl lg:mx-0 text--color-body-2">
            Expertise in emergency response shelter rehabilitation, internal
            displacement and refugees camp planning and execution, WASH
            Infrastructure (Water And Sanitation) project management. Provide
            training and on the job capacity building all the above areas for
            United Nations partners, and NGO humanitarian agency.
          </p>
          <div className="mt-5 lg:flex">
            <div className="">
              <Button isFullWidth as={Link} size="lg" to="/contact/">
                Contact
              </Button>
            </div>
            <div className="mt-3 lg:mt-0 lg:ml-3">
              <Button
                isFullWidth
                // as={Link}
                size="lg"
                // to="/gallery/training-capacity-building"
                onClick={handleOpenVideoModal}
              >
                Watch Introduction
              </Button>
              <Modal
                isCentered
                isOpen={isVideoModalOpen}
                size="full"
                onClose={handleCloseVideoModal}
              >
                <ModalContent onClick={handleCloseVideoModal}>
                  <div css={[styles.container]}>
                    <div
                      css={[styles.videoOuterContainer]}
                      style={{ paddingBottom: '56.25%' }}
                    >
                      <div css={[styles.videoInnerContainer]}>
                        <ReactPlayer
                          playing
                          height="100%"
                          url="https://youtu.be/zOiRWJObev8"
                          volume={0.1}
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                </ModalContent>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <StaticImage
          alt="Raja Kuppuswamy in the field"
          className="w-full h-full"
          src="../../images/hero.jpg"
        />
      </div>
    </div>
  );
}

export const Hero = Component;
